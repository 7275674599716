<template>
  <!-- BEGIN: Content-->
  <div class="card">
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <img
              :src="user.avatarUrl ? user.avatarUrl : require('@/assets/images/avatars/user_default.png')"
              alt="Antonio Acín"
              class="img-fluid"
            >
          </div>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-6">
                <h3>Working place</h3>
                <table class="table table-condensed table-bordered">
                  <tr>
                    <td>Incorporation to ICREA</td>
                    <td>{{ user.start_date }}</td>
                  </tr>
                  <tr>
                    <td>Institution</td>
                    <td>{{ user.hosts[0] ? `${user.hosts[0].name}, ${user.hosts[0].code}` : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Department</td>
                    <td>{{ user.department_aux && user.department_aux.id ? user.department_aux.name : user.department_aux }}</td>
                  </tr>
                  <tr>
                    <td>Researcher ID (WoS)</td>
                    <td>{{ user.researcher_id }}</td>
                  </tr>
                  <tr>
                    <td>Author ID (Scopus)</td>
                    <td>{{ user.author_id }}</td>
                  </tr>
                  <tr>
                    <td>ORCID</td>
                    <td>{{ user.orcid }}</td>
                  </tr>
                  <tr>
                    <td>ERC</td>
                    <td>{{ user.main_ercs ? (user.main_ercs.level3 ? user.main_ercs.level3.custom_name : user.main_ercs.level2.custom_name) : '-' }}</td>
                  </tr>
                </table>
                <div class="row mt-2">
                  <div
                    v-if="!loggedUser.is_pc_member_active"
                    class="col"
                  >
                    <h4>Icrea's Personal Page</h4>
                    <a
                      :href="$router.resolve({ name: 'researcher.administrative-data.researcher', params: { id: user.id }, query: { actAs: $route.query.actAs } }).href"
                      target="_blank"
                      class="btn btn-dark btn-sm"
                    >View personal page</a>
                  </div>
                  <div
                    v-if="user.CVFile[0]"
                    class="col"
                  >
                    <h4>Full CV</h4>
                    <a
                      :href="user.CVFile[0].url"
                      target="_blank"
                      class="btn btn-dark btn-sm"
                    >View CV</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <h3>Keywords</h3>
                <span
                  v-for="keyword in user.keywords"
                  :key="keyword.id"
                  class="badge bg-dark me-25 mb-25"
                >{{ keyword.word }}</span>
                <hr>
                <h3>Short biography</h3>
                <p><small v-html="user.short_biography" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      user: 'users/user',
      loggedUser: 'auth/admin',
    }),
  },
  mounted() {},
}
</script>
